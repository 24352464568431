import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    if (!isLoggedIn) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      navigate('login', { replace: true });
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  // if (!isLoggedIn) {
  //   if (pathname !== requestedLocation) {
  //     setRequestedLocation(pathname);
  //   }
  //   navigate('login', { replace: true });
  // }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    navigate({ requestedLocation }, { replace: true });

    //return <Navigate to={requestedLocation} />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
