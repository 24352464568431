import axios from 'axios';
const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'Content-Type':'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

export default axiosInstance;



