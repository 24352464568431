import { LOGIN, LOGOUT, INITIAL, FIRST_STEP_LOGIN } from './actions';

export const initialState = {
  isFirstStepLoggedIn: false,
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  userRole: null,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIRST_STEP_LOGIN: {
      const { user, userRole } = action.payload;
      return {
        ...state,
        isFirstStepLoggedIn: true,
        isLoggedIn: false,
        isInitialized: true,
        user,
        userRole
      };
    }
    case LOGIN: {
      const { user, userRole } = action.payload;
      return {
        ...state,
        isFirstStepLoggedIn: true,
        isLoggedIn: true,
        isInitialized: true,
        user,
        userRole
      };
    }
    case INITIAL: {
      const { isFirstStepLoggedIn, isLoggedIn, user, userRole } = action.payload;
      return {
        isInitialized: true,
        isFirstStepLoggedIn: isFirstStepLoggedIn,
        isLoggedIn: isLoggedIn,
        user,
        userRole
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isFirstStepLoggedIn: false,
        isLoggedIn: false,
        user: null,
        userRole: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
