import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Toolbar, Typography, IconButton } from '@mui/material';
import SearchIcon from 'icons/SearchIcon';
import LogoutIcon from 'icons/LogoutIcon';
import useAuth from 'hooks/useAuth';

const Header = () => {
  const theme = useTheme();
  const { isLoggedIn, isFirstStepLoggedIn, logout } = useAuth();


  return (
    <Toolbar
      sx={{
        [theme.breakpoints.down('sm')]: {
          height: '64px',
        },
      }}
    >
      <Typography
        variant="h6"
        color={theme.palette.background.default}
        component="div"
        sx={{ flexGrow: 1 }}
        //sx={{ mr: 5 }}
        // sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        {process.env.REACT_APP_ENVIRONMENT_NAME || ''}
      </Typography>

      {isLoggedIn &&(
        <IconButton
        size='medium'
        //edge="start"
        color="inherit"
        //aria-label="open drawer"
        //sx={{ flexGrow: 1 }}
        sx={{ mr: 2 }}
        //sx={{ mr: 7 }}
        onClick={() => {
          //props.setPatientID();
          window.location.href = '/search';
      }}
        edge="end"
      >
        <SearchIcon/>
      </IconButton>
      )}

      { isFirstStepLoggedIn && (
        <IconButton
        size='medium'
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={() => {
          logout();
        }}
      >
        <LogoutIcon />
      </IconButton>
      )
      }
    </Toolbar>
  );
};

export default Header;
