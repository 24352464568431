import React from 'react'
import { Stack, Typography } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions , Button} from '@mui/material';
//import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

// const useStyles = makeStyles(() => ({
//     dialog: {
//         padding: useTheme().spacing(2),
//         position: 'absolute',
//         top: useTheme().spacing(5)
//     },
//     dialogTitle: {
//         textAlign: 'center'
//     },
//     dialogContent: {
//         textAlign: 'center'
//     },
//     dialogAction: {
//         justifyContent: 'center'
//     },
//     titleIcon: {
//         backgroundColor: useTheme().palette.secondary.light,
//         color: useTheme().palette.secondary,
//         '&:hover': {
//             backgroundColor: useTheme().palette.secondary.light,
//             cursor: 'default'
//         },
//         '& .MuiSvgIcon-root': {
//             fontSize: '8rem',
//         }
//     }
// }))

const ConfirmDialog = ({ confirm, setConfirm }) => {
    
   // const classes = useStyles()

    return (
        <Dialog
        open={confirm.open} 
      //  classes={{ paper: classes.dialog }}
        >
            <DialogTitle 
           // className={classes.dialogTitle}
            >
            </DialogTitle>
            <DialogContent 
            //className={classes.dialogContent}
            ><Stack alignItems="center">
                <Typography variant="h6">
                    {confirm.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirm.subTitle}
                </Typography>
                </Stack>
            </DialogContent>
            <DialogActions 
           // className={classes.dialogAction}
            >
            <Button onClick={confirm.onConfirm}>כן</Button>
            <Button onClick={() => setConfirm({ ...confirm, open: false })}>לא</Button>                     
            </DialogActions>  
        </Dialog>
    )
}

export default ConfirmDialog