export const DASHBOARD_PATH = '/main';

export const BASE_PATH = '';

const config = {
    fontFamily: 'assistant',
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    navType: 'light', // light, dark
    locale: 'he', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: true,
};

export default config;
