import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import snackbarReducer from './slices/snackbar';
import patientReducer from './slices/patient';
import patientDetailsReducer from './slices/patientDetails';
import storageSession from 'redux-persist/lib/storage/session'; // Import session storage


const reducer = combineReducers({
  snackbar: snackbarReducer,
  patientDetails: persistReducer(
    {
        key: 'patientDetails',
        storage: storageSession,
        //keyPrefix: 'bbb-'
    },
    patientDetailsReducer
),
  patient: patientReducer
});

export default reducer;
