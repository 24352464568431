import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
//import { DASHBOARD_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
  const navigate = useNavigate();
  const { isLoggedIn, isFirstStepLoggedIn, userRole } = useAuth();

  useEffect(() => {
    if (isFirstStepLoggedIn && userRole && !isLoggedIn) {
      navigate('otp', { replace: true });    
    }
   else if (!isFirstStepLoggedIn) {
    if (window.location.pathname !== '/login'){
      navigate('login', { replace: true });
    }
   }  
  }, [isFirstStepLoggedIn, userRole]);

  useEffect(() => {
    if (isLoggedIn && userRole) {
      navigateToDashboard(); // Redirect to appropriate dashboard based on user role
    }
  }, [isLoggedIn, userRole]);

  const navigateToDashboard = () => {
    //let dashboardPath = DASHBOARD_PATH;

    // Determine the appropriate dashboard path based on the user role
    // if (userRole === 'Caregiver') {
    //   dashboardPath = CAREGIVER_DASHBOARD_PATH;
    // } else {
    //   dashboardPath = DASHBOARD_PATH;
    // }

    // Redirect to the dashboard path
     navigate('/search', { replace: true });
  };

  // if (isLoggedIn) {
  //   return <Navigate to={DASHBOARD_PATH} />;
  // }

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
