// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../index';
import moment from 'moment';
import { setClientId, setIdCard } from './patientDetails';


// ----------------------------------------------------------------------

const initialState = {
  error: null,
  idCard: null,
  patientId: null,
  patientDetails: null,
  diagnoses: null,
  doctor: null,
  nurse: null,
  speechTherapy: null,
  occupationalTherapy: null,
  socialWork: null,
  psychology: null,
  diet: null,
  physiotherapy: null,
  subjects: null,
  doctorMT: null,
  nurseMT: null,
  speechTherapyMT: null,
  occupationalTherapyMT: null,
  socialWorkMT: null,
  psychologyMT: null,
  dietMT: null,
  physiotherapyMT: null,
  medicalTaskCatalog: null,

};

const slice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    setIdCardSuccess(state, action) {
      state.idCard = action.payload;
    },
    setPatientIdSuccess(state, action) {
      state.patientId = action.payload;
    },
    setPatientDetailsSuccess(state, action) {
      state.patientDetails = action.payload;
    },
    setDiagnosesSuccess(state, action) {
      state.diagnoses = action.payload;
    },
    setDoctorSuccess(state, action) {
      state.doctor = action.payload;
    },
    setNurseSuccess(state, action) {
      state.nurse = action.payload;
    },
    setSpeechTherapySuccess(state, action) {
      state.speechTherapy = action.payload;
    },
    setOccupationalTherapySuccess(state, action) {
      state.occupationalTherapy = action.payload;
    },
    setSocialWorkSuccess(state, action) {
      state.socialWork = action.payload;
    },
    setPsychologySuccess(state, action) {
      state.psychology = action.payload;
    },
    setDietSuccess(state, action) {
      state.diet = action.payload;
    },
    setPhysiotherapySuccess(state, action) {
      state.physiotherapy = action.payload;
    },
    setSubjectsSuccess(state, action) {
      state.subjects = action.payload;
    },
    setDoctorMTSuccess(state, action) {
      state.doctorMT = action.payload;
    },
    setNurseMTSuccess(state, action) {
      state.nurseMT = action.payload;
    },
    setSpeechTherapyMTSuccess(state, action) {
      state.speechTherapyMT = action.payload;
    },
    setOccupationalTherapyMTSuccess(state, action) {
      state.occupationalTherapyMT = action.payload;
    },
    setSocialWorkMTSuccess(state, action) {
      state.socialWorkMT = action.payload;
    },
    setPsychologyMTSuccess(state, action) {
      state.psychologyMT = action.payload;
    },
    setDietMTSuccess(state, action) {
      state.dietMT = action.payload;
    },
    setPhysiotherapyMTSuccess(state, action) {
      state.physiotherapyMT = action.payload;
    },
    setMedicalTaskCatalogSuccess(state, action) {
      state.medicalTaskCatalog = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

export const getPtientMedicalTasks = (patientId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/medical_query', {
        patientId: patientId,
      });

      switch (response.data.error_code) {
        case '100':
          dispatch(
            slice.actions.setDoctorMTSuccess(
              response.data.response.filter(
                (item) => item.requesterMedicalGroupId === 1
              ) || []
            )
          );
          dispatch(
            slice.actions.setNurseMTSuccess(
              response.data.response.filter(
                (item) => item.requesterMedicalGroupId === 2
              ) || []
            )
          );
          dispatch(
            slice.actions.setSpeechTherapyMTSuccess(
              response.data.response.filter(
                (item) => item.requesterMedicalGroupId === 20
              ) || []
            )
          );
          dispatch(
            slice.actions.setOccupationalTherapyMTSuccess(
              response.data.response.filter(
                (item) => item.requesterMedicalGroupId === 6
              ) || []
            )
          );
          dispatch(
            slice.actions.setSocialWorkMTSuccess(
              response.data.response.filter(
                (item) => item.requesterMedicalGroupId === 3
              ) || []
            )
          );
          dispatch(
            slice.actions.setPsychologyMTSuccess(
              response.data.response.filter(
                (item) => item.requesterMedicalGroupId === 8
              ) || []
            )
          );
          dispatch(
            slice.actions.setDietMTSuccess(
              response.data.response.filter(
                (item) => item.requesterMedicalGroupId === 5
              ) || []
            )
          );
          dispatch(
            slice.actions.setPhysiotherapyMTSuccess(
              response.data.response.filter(
                (item) => item.requesterMedicalGroupId === 4
              ) || []
            )
          );
          break;
        default:
          break;
      }
    } catch (error) {
      // Handle errors here, you can dispatch an error action if needed
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const setPatientData = async (data) => {
  try {
    await dispatch(slice.actions.setIdCardSuccess(data.details[0].idCard));
    await dispatch(slice.actions.setPatientIdSuccess(data.details[0].clientId));
    await dispatch(slice.actions.setPatientDetailsSuccess(data.details));
    await dispatch(slice.actions.setDiagnosesSuccess(data.diagnoses));
    await dispatch(slice.actions.setDoctorSuccess(data.doctor));
    await dispatch(slice.actions.setNurseSuccess(data.nurse));
    await dispatch(slice.actions.setSpeechTherapySuccess(data.speechTherapy));
    await dispatch(slice.actions.setOccupationalTherapySuccess(data.occupationalTherapy));
    await dispatch(slice.actions.setSocialWorkSuccess(data.socialWork));
    await dispatch(slice.actions.setPsychologySuccess(data.psychology));
    await dispatch(slice.actions.setDietSuccess(data.diet));
    await dispatch(slice.actions.setPhysiotherapySuccess(data.physiotherapy));
    await dispatch(slice.actions.setSubjectsSuccess(data.subjectsList));
  } catch (error) {
   // await cleanSearch();
    await dispatch(slice.actions.hasError(error));
}
};

export const getPatientMedicalTaskCatalog = (medicalGroupId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/medical_catalog', {
        "requesterMedicalGroupId": medicalGroupId,
        "performerMedicalGroupId": 1000
      });
      await dispatch(slice.actions.setMedicalTaskCatalogSuccess(response.data.response));

    } catch (error) {
      // Handle errors here, you can dispatch an error action if needed
      dispatch(slice.actions.hasError(error));
    }
  };
};