import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import Loadable from 'components/ui/Loadable';


import MinimalLayout from 'layout/MinimalLayout';
// import MainLayout from 'layout/MainLayout';
// import Treatments from 'views/dashboard/treatments/Treatments';
// import Main from 'views/dashboard/main';
// import ProfileSetting from 'views/user/profile-setting';
// import MedicalRecord from 'views/dashboard/medical-record';
import GuestGuard from 'utils/route-guard/GuestGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
// import SimpleLayout from 'layout/SimpleLayout';

// AUTHENTICATION
const Login = Loadable(lazy(() => import('pages/Login')));
const CodeVerification = Loadable(lazy(() => import('pages/CodeVerification')));

// dashboard
const Main = Loadable(lazy(() => import('pages/Main')));

const Search = Loadable(lazy(()=> import('pages/Search')));

// const Discharge = Loadable(lazy(()=> import('pages/dashboard/discharge')));

// const DailyPlanner = Loadable(lazy(()=> import('pages/dashboard/daily-planner')));

// const GroupActivity = Loadable(lazy(()=> import('pages/dashboard/group-activity')));

// const Followups = Loadable(lazy(() => import('pages/dashboard/followups')));


// // treatments
// const MainTreatments = Loadable(lazy(() => import('pages/treatments/main')));
// const PatientTreatments = Loadable(lazy(() => import('pages/treatments/patient')));
// const TaskDivision = Loadable(lazy(()=> import('pages/treatments/taskDivision')));

const LoginRoutes = {
  path: '/',
  element: (
    <GuestGuard>
      <MinimalLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/otp',
      element: <CodeVerification />,
    },
  ],
};

// const OtpRoutes = {
//   path: '/',
//   element: (
//     <GuestGuard>
//       <MinimalLayout />
//     </GuestGuard>
//   ),
//   children: [
//     {
//       path: '/otp',
//       element: <CodeVerification />,
//     },
//   ],
// };

// const SimpleRoutes = {
//   path: '/',
//   element: (
//     <AuthGuard>
//       <SimpleLayout />
//     </AuthGuard>
//   ),
//   children: [
//     // {
//     //   path: '/treatments/main',
//     //   element: <MainTreatments />,
//     // },
//     // {
//     //   path: '/treatments/patient/:id',
//     //   element: <PatientTreatments />,
//     // },
//     // {
//     //   path: '/treatments/task-division',
//     //   element: <TaskDivision />,
//     // },
//   ],
// };

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MinimalLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/search',
      element: <Search />,
    },
    {
      path: '/main',
      element: <Main />,
    },
    // // {
    // //   path: '/treatments',
    // //   element: <Treatments />,
    // // },
    // {
    //   path: '/dashboard/follow-ups',
    //   element: <Followups />,
    // },
    // {
    //   path: '/medical-record',
    //   element: <MedicalRecord />,
    // },
    // // new
    // {
    //   path: '/dashboard/admission',
    //   element: <Admission />,
    // },
    // {
    //   path: '/dashboard/discharge',
    //   element: <Discharge />,
    // },
    // {
    //   path: '/dashboard/daily-planner',
    //   element: <DailyPlanner />,
    // },
    // {
    //   path: '/dashboard/group-activity',
    //   element: <GroupActivity />,
    // },
  ],
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Navigate to="/login" replace /> },
    LoginRoutes,
    MainRoutes,
  ]);
}
