import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header';
import { AppBar, Box, CssBaseline } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const Main = styled('main')(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  }),
}));

const MinimalLayout = () => {
  const theme = useTheme();

  const header = useMemo(() => <Header />, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          width: '100%',
          bgcolor: theme.palette.primary.main,
          borderBottom: '1px solid',
          borderColor: theme.palette.divider,
          //transition: drawerOpen ? theme.transitions.create('width') : 'none',
          [theme.breakpoints.down('sm')]: {
            height: '64px',
          },
        }}
      >
        {header}
      </AppBar>
      <Main theme={theme}>
        <Outlet />
      </Main>
    </Box>
  );
};

export default MinimalLayout;
