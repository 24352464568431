import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

const initialState = {
  error: null,
  idCard: null,
  clientId: null,
};

const slice = createSlice({
  name: 'patientDetails',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // ADD PRODUCT
    setIdCardSuccess(state, action) {
      state.idCard = action.payload;
    },
    setClientIdSuccess(state, action) {
      state.clientId = action.payload;
    },

    removePatientDetailsSuccess(state, action) {
      state.idCard = null;
      state.clientId = null;
    },
  },
});

// Reducer
export default slice.reducer;

export function setIdCard(idCard) {
  return async () => {
      try {
          dispatch(slice.actions.setIdCardSuccess(idCard));
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  };
}

export function setClientId(clientId) {
  return async () => {
      try {
          dispatch(slice.actions.setClientIdSuccess(clientId));
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  };
}
