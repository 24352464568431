import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";

function IdleTimerContainer(){
    const IdleTimerRef = useRef(null);

    const onIdle = () => {
        console.log("user is Idle!");
        sessionStorage.clear();
        window.location.href = '/';
    }

    return (
        <div>
            <IdleTimer 
               ref={IdleTimerRef} 
               timeout={30*60*1000} //30 דקות
               onIdle={onIdle}>
            </IdleTimer>
        </div>
    )
}

export default IdleTimerContainer