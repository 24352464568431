import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch as storeDispatch } from 'store';

// reducer - state management
import { FIRST_STEP_LOGIN, LOGIN, LOGOUT } from 'store/actions';
import accountReducer, { initialState } from 'store/accountReducer';

// project imports
import Loader from 'components/ui/Loader';
import axios from 'utils/axios';

export const setSession = (key, value, isAxios = false) => {
  debugger;
  if (value) {
    sessionStorage.setItem(key, value);
    if (isAxios) {
      axios.defaults.headers.common.Authorization = `${value}`;
    }
  } else {
    // sessionStorage.removeItem(key);
    // if (isAxios) {
    //   delete axios.defaults.headers.common.Authorization;
    // }
    sessionStorage.clear();
    delete axios.defaults.headers.common.Authorization;
  }
};

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const tempToken = window.sessionStorage.getItem('tempToken');
        const userStr = window.sessionStorage.getItem('userDetails');
        const serviceToken = window.sessionStorage.getItem('serviceToken');
        if (tempToken && userStr && !serviceToken) {
          setSession('tempToken', tempToken, true);
          const user = JSON.parse(userStr);
          const userRole = user.followId.toString();
          dispatch({
            type: FIRST_STEP_LOGIN,
            payload: {
              user,
              userRole,
            },
          });
        } else if (serviceToken && userStr) {
          setSession('serviceToken', serviceToken, true);
          const user = JSON.parse(userStr);
          const userRole = user.followId.toString();
          dispatch({
            type: LOGIN,
            payload: {
              user,
              userRole,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };
    init();
  }, []);

  const login = async (username, password) => {
    const response = await axios.post('/auth', {
      username,
      password,
    });
    switch (response.data.error_code) {
      case null:
        break;
      case '100':
        setSession('tempToken', response.data.response.token, true);
        setSession('userDetails', JSON.stringify(response.data.response.user_details));
        const user = response.data.response.user_details;
        const userRole = user.followId.toString();
        dispatch({
          type: FIRST_STEP_LOGIN,
          payload: {
            user,
            userRole,
          },
        });
        break;
      default:
        storeDispatch(
          openSnackbar({
            open: true,
            message: response?.data?.error_message,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          })
        );
    }
  };

  const verifyCode = async (code) => {
    const response = await axios.post('/mfaverify', {
      code,
    });
    switch (response.data.error_code) {
      case null:
        break;
      case '100':
        setSession('serviceToken', response.data.response.token, true);
        const userStr = window.sessionStorage.getItem('userDetails');
        const user = JSON.parse(userStr);
        const userRole = user.followId.toString();
        dispatch({
          type: LOGIN,
          payload: {
            user,
            userRole,
          },
        });
        break;
      default:
        storeDispatch(
          openSnackbar({
            open: true,
            message: response?.data?.error_message,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          })
        );
    }
  };

  const resendCode = async () => {
    await axios.get('/resend');
  };

  const logout = () => {
    debugger;
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  //const resetPassword = (email) => console.log(email);

  //const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{ ...state, logout, login, verifyCode, resendCode }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
