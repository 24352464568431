import React from 'react';
import ThemeCustomization from 'themes';
import RTLLayout from 'components/ui/RTLLayout';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import Snackbar from 'components/ui/Snackbar';
import Routes from 'routes';
import ConfirmDialogProvider from 'components/confirmDialog/ConfirmDialogProvider';
function App() {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <AuthProvider>
        <ConfirmDialogProvider>
          <>
            <Routes />
            <Snackbar />
          </>
          </ConfirmDialogProvider>
        </AuthProvider>
      </RTLLayout>
    </ThemeCustomization>
  );
}

export default App;
