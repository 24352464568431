import { useState } from 'react';
import { ConfirmDialogContextProvider } from './ConfirmDialogContext';
import ConfirmDialog from './ConfirmDialog';

const ConfirmDialogProvider = ({children}) => {

    const [confirm, setConfirm] = useState({ 
        open: false,
        title: "",
        subTitle: "",
        onConfirm: () => {console.log("")}
        });

        const confirmOptions = {
          confirm,
          setConfirm
        }

    return (
        <>
        <ConfirmDialogContextProvider value={confirmOptions}>
        {children}
       </ConfirmDialogContextProvider>
       <ConfirmDialog confirm={confirm} setConfirm={setConfirm}></ConfirmDialog>
       </>
    )
}
export default ConfirmDialogProvider