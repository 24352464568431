import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'App';
import { store, persister } from 'store';
import IdleTimerContainer from 'components/IdleTimer';

// const theme = createTheme({
//   direction: 'rtl',
//   typography: {
//     fontFamily:'assistant'
//   },
//   palette: {
//     primary: {
//       light: '#757ce8',
//       main: '#1a237e',
//       dark: '#002884',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff7961',
//       main: '#bbdefb',
//       dark: '#64b5f6',
//       contrastText: '#000',
//     },
//   }
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter>
      <IdleTimerContainer />
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
